import React from 'react'

/**
 * Video background two-tone blue swoosh.
 */
export const PhoneVideoBackground = () => {
  return (
    <svg
      width="600"
      height="589"
      viewBox="0 0 600 589"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3_19)">
        <path
          d="M458.735 98.2493C517.221 62.5902 600 0.233398 600 0.233398V393.233H-304V224.671C-304 224.671 -183.141 247.511 -104.764 248.395C-16.4242 249.391 33.9133 245.573 120.573 228.417C259.4 200.932 337.935 171.901 458.735 98.2493Z"
          fill="#DEEBFF"
        />
        <path
          d="M458.735 193.249C517.221 157.59 600 95.2334 600 95.2334V488.233H-304V321.472C-304 321.472 -182.764 342.511 -104.387 343.395C-16.0475 344.391 33.9133 340.573 120.573 323.417C259.4 295.932 337.935 266.901 458.735 193.249Z"
          fill="#D5E2F5"
        />
        <path
          d="M458.735 293.249C517.221 257.59 600 195.233 600 195.233V588.233H-304V421.472C-304 421.472 -182.764 442.511 -104.387 443.395C-16.0475 444.391 33.9133 440.573 120.573 423.417C259.4 395.932 337.935 366.901 458.735 293.249Z"
          fill="#F5F5F5"
        />
      </g>
      <defs>
        <clipPath id="clip0_3_19">
          <rect width="600" height="589" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
export const TabletVideoBackground = () => {
  return (
    <svg
      width="1350"
      height="791"
      viewBox="0 0 1350 791"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1139.04 130.189C1226.38 82.8251 1350 0 1350 0V522H0V298.108C0 298.108 180.487 328.445 297.531 329.619C429.455 330.942 504.627 325.872 634.041 303.083C841.361 266.577 958.642 228.016 1139.04 130.189Z"
        fill="#DEEBFF"
      />
      <path
        d="M1139.04 256.189C1226.38 208.825 1350 126 1350 126V648H0V426.5C0 426.5 181.049 454.445 298.094 455.619C430.018 456.942 504.627 451.872 634.041 429.083C841.361 392.577 958.642 354.016 1139.04 256.189Z"
        fill="#D5E2F5"
      />
      <path
        d="M1139.04 399.189C1226.38 351.825 1350 269 1350 269V791H0V569.5C0 569.5 181.049 597.445 298.094 598.619C430.018 599.942 504.627 594.872 634.041 572.083C841.361 535.577 958.642 497.016 1139.04 399.189Z"
        fill="#F5F5F5"
      />
    </svg>
  )
}

export const DesktopVideoBackground = () => {
  return (
    <svg
      width="1800"
      height="994"
      viewBox="0 0 1800 994"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1_2)">
        <rect width="1800" height="594" fill="white" />
        <path
          d="M1529.38 152.877C1646.34 95.759 1811.88 -4.12256 1811.88 -4.12256V625.377H-10L-12.5 355.377C-12.5 355.377 245.764 391.962 402.5 393.377C579.161 394.973 679.825 388.859 853.125 361.377C1130.75 317.353 1287.8 270.851 1529.38 152.877Z"
          fill="#DEEBFF"
        />
        <path
          d="M1529.38 275.877C1646.34 218.759 1811.88 118.877 1811.88 118.877V748.377H-10L-12.5 478.377C-12.5 478.377 245.764 514.962 402.5 516.377C579.161 517.973 679.825 511.859 853.125 484.377C1130.75 440.353 1287.8 393.851 1529.38 275.877Z"
          fill="#D5E2F5"
        />
        <path
          d="M1529.38 504.877C1646.34 447.759 1811.88 347.877 1811.88 347.877V993.877H-10L-12.5 707.377C-12.5 707.377 245.764 743.962 402.5 745.377C579.161 746.973 679.825 740.859 853.125 713.377C1130.75 669.353 1287.8 622.851 1529.38 504.877Z"
          fill="#F5F5F5"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_2">
          <rect width="1800" height="994" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export const LargeDesktopVideoBackground = () => {
  return (
    <svg
      width="3201"
      height="1028"
      viewBox="0 0 3201 1028"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="1" width="3200" height="400" fill="white" />
      <path
        d="M2704.49 157.873C2909.64 100.437 3200 0 3200 0V633H4.38506L0 361.499C0 361.499 453.001 398.287 727.92 399.71C1037.79 401.315 1214.36 395.167 1518.33 367.532C2005.29 323.263 2280.76 276.503 2704.49 157.873Z"
        fill="#DEEBFF"
      />
      <path
        d="M2704.49 276.857C2909.64 219.427 3200 119 3200 119V751.936L509.695 824L4.38506 751.936L0 480.462C0 480.462 453.001 517.247 727.92 518.67C1037.79 520.274 1214.36 514.127 1518.33 486.495C2005.29 442.231 2280.76 395.475 2704.49 276.857Z"
        fill="#D5E2F5"
      />
      <path
        d="M2704.49 535.972C2909.64 478.5 3200 378 3200 378V1028H4.38506L0 739.726C0 739.726 453.001 776.538 727.92 777.961C1037.79 779.567 1214.36 773.415 1518.33 745.763C2005.29 701.467 2280.76 654.677 2704.49 535.972Z"
        fill="#F5F5F5"
      />
    </svg>
  )
}
