import React from 'react'

import classNames from 'classnames'
import {
  CloudinaryImage,
  Layout,
  Spacer,
  TitleSmall,
} from 'ethos-design-system'

import Markdown from '../global/Markdown'
import VideoEmbed from '../global/VideoEmbed'
import styles from './Video.module.scss'
import {
  DesktopVideoBackground,
  LargeDesktopVideoBackground,
  PhoneVideoBackground,
  TabletVideoBackground,
} from './VideoBackground'

interface VideoTemplateProps {
  mediaTitle: string
  youtubeId: string
  heading?: string
  fallbackThumbnail?: string
  subHeading?: string
  backgroundImage?: string
}

export const VideoTemplate = ({
  heading,
  subHeading,
  mediaTitle,
  youtubeId,
  fallbackThumbnail,
  backgroundImage,
}: VideoTemplateProps) => {
  return (
    <div
      className={classNames(
        styles.content,
        backgroundImage && styles.containsBackground
      )}
    >
      {heading && (
        <div className={styles.heading}>
          <TitleSmall.Sans.Regular400>
            <Markdown input={heading} />
          </TitleSmall.Sans.Regular400>
          <Spacer.H56 />
        </div>
      )}
      {subHeading && (
        <div className={styles.subHeading}>
          <TitleSmall.Sans.Regular400>
            <Markdown input={subHeading} />
          </TitleSmall.Sans.Regular400>
          {backgroundImage ? <Spacer.H32 /> : <Spacer.H56 />}
        </div>
      )}
      <div className={styles.video}>
        <VideoEmbed
          youtubeId={youtubeId}
          videoTitle={mediaTitle}
          fallbackThumbnail={fallbackThumbnail}
        />
      </div>
    </div>
  )
}
interface VideoProps {
  moduleData: VideoTemplateProps
}

const Video = ({ moduleData }: VideoProps) => {
  return (
    <div
      className={classNames(
        styles.outerContainer,
        moduleData.backgroundImage && styles.containsBackground
      )}
    >
      {moduleData.backgroundImage && (
        <CloudinaryImage
          publicId={moduleData.backgroundImage}
          width={[375, 1440]}
          height={[597, 957]}
          alt="Video background"
          className="absolute inset-0 mx-auto block h-full"
        />
      )}
      <div className={styles.container}>
        <div className={styles.background}>
          <div className={styles.phoneBackground}>
            {!moduleData.backgroundImage && <PhoneVideoBackground />}
          </div>
          <div className={styles.tabletBackground}>
            {!moduleData.backgroundImage && <TabletVideoBackground />}
          </div>
          <div className={styles.desktopBackground}>
            {!moduleData.backgroundImage && <DesktopVideoBackground />}
          </div>
          <div className={styles.largeDesktopBackground}>
            {!moduleData.backgroundImage && <LargeDesktopVideoBackground />}
          </div>
        </div>
        <div className={styles.foreground}>
          <Layout.HorizontallyPaddedContainer>
            <VideoTemplate {...moduleData} />
          </Layout.HorizontallyPaddedContainer>
        </div>
        {!moduleData.backgroundImage && (
          <div className={styles.bottomBackground} />
        )}
        <div className={styles.buffer} />
      </div>
    </div>
  )
}

export default Video
